import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { HistoryRoute } from '@app/history/shared/history.route.enum';
import { AppRoute } from '@app/shared/app.route.enum';
import { BaseDatabase } from '@app/shared/base/components/base-database.component';
import { BaseDataSource } from '@app/shared/base/components/base-datasource.component';
import { BaseDisplayFilterableTableComponent } from '@app/shared/base/components/base-display-filterable-table.component';
import { DatabaseConfiguration } from '@app/shared/base/models/database-configuration';
import { ConfirmDialogComponent, ConfirmDialogData } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { LocalStorageDataSource } from '@app/shared/components/local-storage/local-storage-datasource.component';
import { JadSelectionModel } from '@app/shared/functions/jad-selection-model';
import { __ } from '@app/shared/functions/object.functions';
import { Artist } from '@app/shared/models/classes/Artist';
import { Genre } from '@app/shared/models/classes/Genre';
import { Instrument } from '@app/shared/models/classes/Instrument';
import { Mood } from '@app/shared/models/classes/Mood';
import { Theme } from '@app/shared/models/classes/Theme';
import { Track, TrackVariation } from '@app/shared/models/classes/Track';
import { SubSubNavigationView } from '@app/shared/models/enums/SubSubNavigationView.enum';
import { TrackSort } from '@app/shared/models/enums/TrackSorts.enum';
import { TrackVariationStatus } from '@app/shared/models/enums/TrackVariationStatus.enum';
import { SortingFilters } from '@app/shared/models/helpers/SortingFilters.helper';
import { TrackHeader, TrackHeadersHelper } from '@app/shared/models/helpers/TrackHeaders.helper';
import { TrackSortsHelper } from '@app/shared/models/helpers/TrackSorts.helper';
import { TrackStatusHelper } from '@app/shared/models/helpers/TrackStatus.helper';
import { ArtistService } from '@app/shared/services/artist.service';
import { GenreService } from '@app/shared/services/genre.service';
import { InstrumentService } from '@app/shared/services/instrument.service';
import { DownloadsHistoryService } from '@app/shared/services/local/downloads-history.service';
import { FavoritesService } from '@app/shared/services/local/favorites.service';
import { ListeningHistoryService } from '@app/shared/services/local/listening-history.service';
import { BaseMusicFilterSort, MusicFilter, MusicFilterService, MusicSort } from '@app/shared/services/local/music-filter.service';
import { MusicPlayerService } from '@app/shared/services/local/music-player.service';
import { MusicPlaylistService } from '@app/shared/services/local/music-playlist.service';
import { SelectionService } from '@app/shared/services/local/selection.service';
import { SidebarService } from '@app/shared/services/local/side-bar.service';
import { MoodService } from '@app/shared/services/mood.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { TracksService } from '@app/shared/services/tracks.service';
import { TrackVariationsService } from '@app/shared/services/trackvariations.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Moment } from 'moment';
import { Observable, catchError, concatMap, debounceTime, filter, finalize, fromEvent, of, switchMap } from 'rxjs';

@Component({
    selector: 'sound-tracks-list',
    templateUrl: './tracks-list.component.html',
    styleUrls: ['./tracks-list.component.scss'],
    standalone: false
})
export class TracksListComponent extends BaseDisplayFilterableTableComponent<Track> implements OnInit, AfterViewInit {

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  moods: FilterSelectItem[] = [];

  themes: FilterSelectItem[] = [];

  genres: FilterSelectItem[] = [];

  artists: FilterSelectItem[] = [];

  instruments: FilterSelectItem[] = [];

  status: FilterSelectItem[] = [];

  inADay: Moment =  moment().add(1, 'days');

  viewWidth: number = window.innerWidth - 224;

  isExpanded: boolean = false;

  isSubSubSidebarExpanded: boolean = false;

  isBrowseSidebar: boolean = false;

  // ! NOT A FILTER BUT INFORMATION DISPLAY DETERMINER
  songInfoControl: UntypedFormControl = new UntypedFormControl(false);

  sortControl: UntypedFormControl = new UntypedFormControl(null);

  paginatorForm: UntypedFormGroup;

  TrackSorts = TrackSortsHelper.TrackSorts;

  TrackHeaders = TrackHeadersHelper.GetTrackHeadersForHome();

  trackHeaders = this.TrackHeaders;

  selection: JadSelectionModel<Track>;

  filtersForm: UntypedFormGroup;

  TracksFilter = TracksFilter;

  isFiltered: boolean = false;

  isDeleteLoading: boolean = false;

  isPublishLoading: boolean = false;

  isUnpublishLoading: boolean = false;

  descriptionColSpan: number = 8;

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() listTitle: string;

  @Input() selectable: boolean = false;

  @Input() isDeletable: boolean = false;

  @Input() permissions: { canRate: boolean; canComment: boolean };

  @Input() listTitleClass: string;

  @Input() showExtraFilters: boolean = false;

  @Input() showCount: boolean = false;

  @Input() pageSize: number = 10;

  @Input() pageSizeOptions: number[] = [10, 25, 50]

  @Input() endpoint: string = 'tracks?variationsStatus=Online';

  @Input() playlistId: string;

  @Input() ignoreFilters: boolean = false;

  Array = Array;

  private _trackListId: string;
  @Input()
  public set trackListId(trackListId: string) {
    this._trackListId = trackListId;

    this.selection = new JadSelectionModel<Track>(true, trackListId);
    this.selectionService.add(this.selection);
  }
  public get trackListId(): string {
    return this._trackListId;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ OUTPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  // tslint:disable-next-line:member-ordering
  @Output() deleteClicked: EventEmitter<Track> = new EventEmitter<Track>();

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    protected injector: Injector,
    private fb: UntypedFormBuilder,
    private router: Router,
    private location: Location,
    private dialog: MatDialog,
    private genreService: GenreService,
    private moodService: MoodService,
    private themeService: ThemeService,
    private instrumentService: InstrumentService,
    private authenticationService: AuthenticationService,
    private artistService: ArtistService,
    private selectionService: SelectionService<Track>,
    private musicFilterService: MusicFilterService,
    private cdr: ChangeDetectorRef,
    private favoriteService: FavoritesService,
    private downloadsHistoryService: DownloadsHistoryService,
    private listeningHistoryService: ListeningHistoryService,
    private trackVariationsService: TrackVariationsService,
    private translateService: TranslateService,
    private musicPlaylistService: MusicPlaylistService,
    private musicPlayerService: MusicPlayerService,
    private sidebarService: SidebarService,
    private tracksService: TracksService
  ) {
    super(injector, musicFilterService);

    this.setupPaginator();
    super.initAndMergeObservables();
    super.calculateFilterValues();

    this.filtersForm = this.fb.group({
      search: [''],
      artistPseudonym: [[]],
      genreNormalizedName: [[]],
      moodNormalizedName: [[]],
      instrumentNormalizedName: [[]],
      statusNormalizedName: [[]],
      themeNormalizedName: [[]]
    });
  }

  // ----------------------------------------------------------------------------------------------------- 
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    // Limit headers for path creator and curator
    this.loadHeadersForCreatorOrCuratorRoute();;

    this.setTrackHeaderByViewWidth();

    window.addEventListener('resize', this.resize, true);

    this.setDataSourceByRouter();

    this.isFiltered = this.filterService.filters.length > 0;

    // Load filters, register filters and filter changes to music filter service    
    if (this.showExtraFilters === true) {
      this.loadFormFieldData();
      this.subscribeToFilterChanges(this.filtersForm.controls.search, TracksFilter.Search);
    }

    super.addSubscription(
      this.filterService.filters$.subscribe({
        next: (filters: BaseMusicFilterSort[]) => {
          this.isFiltered = filters.length > 0;

          if (!filters.some(q => this.TrackSorts.map(a => a.name).includes(q.key))) {
            this.sortControl.reset(null);
          }
          if (this.showExtraFilters === true) {
            this.updateFiltersForm(filters);
          }
        }
      })
    );

    if (this.router.url.includes(AppRoute.Browse)) {
      this.isBrowseSidebar = true;
    }

    //this.isSubSubSidebarExpanded = this.sidebarService.subSubSideBarIsExpanded;

    this.resize(null);

    super.addSubscription(
      this.sidebarService.showSideBarExpanded$.subscribe({
        next: (expanded: boolean) => {
          this.isExpanded = expanded;

          this.resize(null);
        }
      })
    );

    super.addSubscription(
      this.musicPlayerService.trackProjectChanges$.subscribe({
        next: (changes: { trackId: string, projectIds: string[] }) => {
          const track = this.dataSource.database.currentSet.find(q => q.id == changes.trackId);
          if (!__.IsNullOrUndefinedOrEmpty(track)) {
            track.projectIds = changes.projectIds;
          }
        }
      })
    );

    super.addSubscription(
      this.sidebarService.showSubBrowseBar$.subscribe({
        next: (value: [boolean, SubSubNavigationView]) => {
          this.isSubSubSidebarExpanded = value[0];
          this.resize(null);
        }
      })
    )

    this.trackSortChanges();

    super.trackLoading();
    // super.seedInitialLoadingData(Track);
  }

  ngAfterViewInit() {
    super.connectDatasource();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  setDataSourceByRouter(): void {
    // Listening history in history
    if (this.router.url.includes(AppRoute.History) && this.location.path(false).includes(HistoryRoute.ListeningHistory)) {
      this.setLocalStorageDataSource(this.listeningHistoryService.listenedTracks, this.listeningHistoryService.listenedTracks$);
      return;
    }
    // Downloads in history
    if (this.router.url.includes(AppRoute.History) && this.location.path(false).includes(HistoryRoute.Downloads)) {
      this.setLocalStorageDataSource(this.downloadsHistoryService.downloadedTracks, this.downloadsHistoryService.downloadedTracks$);
      return;
    }
    this.setDataSource();
  }

  subscribeToFilterChanges(control: AbstractControl, filterName: string, items?: FilterSelectItem[]): void {
    super.addSubscription(
      control.valueChanges
        .pipe(
          debounceTime(300)
        ).subscribe({
          next: (values: any) => {
            this.updateMusicFilter(filterName, values, items);
          }
        })
    )
  }

  loadHeadersForCreatorOrCuratorRoute(): void {
    if (this.router.url.includes(AppRoute.Curator)) {
      this.TrackHeaders = TrackHeadersHelper.GetTrackHeadersForCurator();
      this.reflectFiltersInUrl = false;
    }
    if (this.router.url.includes(AppRoute.Creator)) {
      this.TrackHeaders = TrackHeadersHelper.GetTrackHeadersForCreator();
      this.reflectFiltersInUrl = false;
    }
  }

  deleteSelectedTracks(): void {
    super.addSubscription(
      this.dialog.open(ConfirmDialogComponent, {
        data: Object.assign(new ConfirmDialogData(), {
          icon: 'trash',
          displayText: 'Tracks.Do you want to delete this'
        }),
        maxWidth: '58.571rem'
      }).afterClosed()
        .pipe(
          switchMap((isConfirmed: boolean) => {
            if (isConfirmed === true) {
              this.isDeleteLoading = true;
              let observable = of(true);

              for (const selection of this.selection.selected) {
                observable = observable.pipe(
                  concatMap((success: boolean) => {
                    return this.tracksService.deleteById(selection.id);
                  })
                );
              }

              return observable;
            }
            return of(null);
          }),
          catchError((error: any) => {
            this.isDeleteLoading = false;
            return of(null);
          }),
          finalize(() => {
            this.isDeleteLoading = false;
          })
        )
        .subscribe({
          next: (success: boolean) => {
            if (success === true) {
              if (this.selection.selected.length > 1) {
                this.toastr.success(this.translateService.instant('Tracks.The tracks have been successfully removed'));
              } else {
                this.toastr.success(this.translateService.instant('Tracks.The track has been successfully removed'));
              }

              this.dataSource.reloadData();
            }
          },
          error: (error: any) => {
            this.toastr.error(this.translateService.instant('Tracks.Something went wrong'));
            throw error;
          }
        })
    );
  }

  trackSortChanges(): void {
    const currentSort = TrackSortsHelper.TrackSorts.find(q => this.filterService.filters.some(p => p.key === q.name));

    if (!this.IsNullOrUndefinedOrEmpty(currentSort)) {
      this.filterService.pushOrUpdateFilterOrSort(this.getTrackSortFilter(currentSort), false);
      this.sortControl.setValue(currentSort.value);
    }

    super.addSubscription(
      this.sortControl.valueChanges.subscribe({
        next: (value: string) => {
          const trackSort = TrackSortsHelper.GetTrackSortsByValueOrNull(value);

          if (!this.IsNullOrUndefinedOrEmpty(trackSort)) {
            const sortsToRemove = TrackSortsHelper.TrackSorts.filter(q => q.value !== value).map(q => q.displayName);

            if (!this.IsNullOrUndefinedOrEmpty(trackSort.name)) {
              this.filterService.pushOrUpdateFilterOrSort(this.getTrackSortFilter(trackSort));
            }

            sortsToRemove.map(q => this.filterService.removeFilterByDisplayName(q));
          }
        }
      })
    );

    const activeSortFilter = this.filterService.getActiveSortField();

    if (!this.IsNullOrUndefinedOrEmpty(activeSortFilter) && activeSortFilter.key !== this.TrackSorts.find(q => q.value == TrackSort.Newest).name) {
      // this.sortControl.patchValue(null);
    } else {
      this.sortControl.patchValue(TrackSort.Newest);
    }

    super.addSubscription(
      this.filterService.sort$.subscribe({
        next: (sort: MusicSort) => {
          if (!this.IsNullOrUndefinedOrEmpty(sort)) {
            this.sortControl.reset(null);
          }
        }
      })
    );
    super.addSubscription(
      this.filterService.filters$.subscribe({
        next: (filters: MusicFilter[]) => {
          const applicableSort = filters.find(q => this.TrackSorts.some(a => a.name === q.key));

          const mapped = (SortingFilters as any)[this.sortControl.value];

          if (!__.IsNullOrUndefined(applicableSort) && mapped !== applicableSort.key) {
            const applicableTrackSort = this.TrackSorts.find(q => q.name === applicableSort.key);
            this.sortControl.setValue(applicableTrackSort?.value, { emitEvent: false });
            this.cdr.detectChanges();
          }
        }
      })
    )
  }

  unpublishSelectedTracks(): void {
    super.addSubscription(
      this.dialog.open(ConfirmDialogComponent, {
        data: Object.assign(new ConfirmDialogData(), {
          icon: 'reject',
          displayText: 'Tracks.Do you want to unpublish this'
        }),
        maxWidth: '58.571rem'
      }).afterClosed()
        .pipe(
          switchMap((isConfirmed: boolean) => {
            if (isConfirmed === true) {
              this.isUnpublishLoading = true;
              const trackVariations: TrackVariation[] = [];
              this.selection.selected.map(q => trackVariations.push(...q.variations));

              return this.trackVariationsService.updateTrackVariationStatusByIds([...trackVariations.map(q => q.id)], TrackVariationStatus.Offline);
            }
            return of(null);
          }),
          catchError((error: any) => {
            this.isUnpublishLoading = false;
            return of(null);
          }),
          finalize(() => {
            this.isUnpublishLoading = false;
          })
        )
        .subscribe({
          next: (response: string) => {
            if (!this.IsNullOrUndefined(response)) {
              if (this.selection.selected.length > 1) {
                this.toastr.success(this.translateService.instant('Tracks.The tracks have been successfully unpublished'));
              } else {
                this.toastr.success(this.translateService.instant('Tracks.The track has been successfully unpublished'));
              }

              this.dataSource.reloadData();
            }
          },
          error: (error: any) => {
            this.toastr.error(this.translateService.instant('Tracks.Something went wrong'));
            throw error;
          }
        })
    );
  }

  publishSelectedTracks(): void {
    super.addSubscription(
      this.dialog.open(ConfirmDialogComponent, {
        data: Object.assign(new ConfirmDialogData(), {
          icon: 'publish',
          displayText: 'Tracks.Do you want to publish this'
        }),
        maxWidth: '58.571rem'
      }).afterClosed()
        .pipe(
          switchMap((isConfirmed: boolean) => {
            if (isConfirmed === true) {
              this.isPublishLoading = true;
              const trackVariations: TrackVariation[] = [];
              this.selection.selected.map(q => trackVariations.push(...q.variations));

              return this.trackVariationsService.updateTrackVariationStatusByIds([...trackVariations.map(q => q.id)], TrackVariationStatus.Online);
            }
            return of(null);
          }),
          catchError((error: any) => {
            if (error.error.code.internalCode === 1050) {
              this.toastr.error("Some of the tracks you have tried to publish have unpublished artists. Please ask the admin to enable the respective artists if you wish to publish said tracks.");
            }
            this.isPublishLoading = false;
            return of(null);
          }),
          finalize(() => {
            this.isPublishLoading = false;
          })
        )
        .subscribe({
          next: (success: string) => {
            if (!this.IsNullOrUndefined(success)) {
              if (this.selection.selected.length > 1) {
                this.toastr.success(this.translateService.instant('Tracks.The tracks have been successfully published'));
              } else {
                this.toastr.success(this.translateService.instant('Tracks.The track has been successfully published'));
              }
              this.dataSource.reloadData();
            }
          },
          error: (error: any) => {
            this.toastr.error(this.translateService.instant('Tracks.Something went wrong'));
            throw error;
          }
        })
    );
  }

  loadFormFieldData(): void {

    // Artists
    super.addSubscription(
      this.artistService.getAll('orderby=pseudonym', 0, 10000, null, true
      ).subscribe({
        next: (artists: Artist[]) => {
          this.artists = artists.map(q => this.getFilterValue(q, TracksFilter.Artist));

          this.subscribeToFilterChanges(this.filtersForm.controls.artistPseudonym, TracksFilter.Artist, this.artists);
        }
      })
    );

    // Genres
    super.addSubscription(
      this.genreService.getAll('orderby=displayName&isActive=true', 0, 10000, null, false
      ).subscribe({
        next: (genres: Genre[]) => {
          this.genres = genres
            .filter(q => !this.IsNullOrUndefinedOrEmpty(q.displayName))
            .map(q => {
              // filter
              q.subGenres = q.subGenres.filter(p => p.isActive === true);
              return q;
            })
            .map(q => this.getFilterValue(q, TracksFilter.Genre));

          this.subscribeToFilterChanges(this.filtersForm.controls.genreNormalizedName, TracksFilter.Genre, this.genres);
        }
      })
    );

    // Moods
    super.addSubscription(
      this.moodService.getAll('orderby=displayName&isActive=true', 0, 10000, null, false).subscribe({
        next: (moods: Mood[]) => {
          this.moods = moods.map(q => this.getFilterValue(q, TracksFilter.Mood));

          this.subscribeToFilterChanges(this.filtersForm.controls.moodNormalizedName, TracksFilter.Mood, this.moods);
        }
      })
    );

    // Themes
    super.addSubscription(
      this.themeService.getAll('orderby=displayName&isActive=true', 0, 10000, null, false).subscribe({
        next: (themes: Theme[]) => {
          this.themes = themes.map(q => this.getFilterValue(q, TracksFilter.Theme));

          this.subscribeToFilterChanges(this.filtersForm.controls.themeNormalizedName, TracksFilter.Theme, this.themes);
        }
      })
    );

    // Instruments
    super.addSubscription(
      this.instrumentService.getAll('orderby=displayName&isActive=true', 0, 10000, null, false).subscribe({
        next: (instruments: Instrument[]) => {
          this.instruments = instruments.map(q => this.getFilterValue(q, TracksFilter.Instrument));

          this.subscribeToFilterChanges(this.filtersForm.controls.instrumentNormalizedName, TracksFilter.Instrument, this.instruments);
        }
      })
    );

    // Status
    const trackstatus: any[] = TrackStatusHelper.TrackStatus;
    this.status = trackstatus.map(q => this.getFilterValue(q, TracksFilter.Status));

    this.subscribeToFilterChanges(this.filtersForm.controls.statusNormalizedName, TracksFilter.Status, this.status);
  }

  getFilterValue(item: any, type: string): FilterSelectItem {
    let castedItem;
    switch (type) {
      case TracksFilter.Artist:
        castedItem = (item as Artist);

        return Object.assign(new FilterSelectItem(), {
          displayName: castedItem.pseudonym,
          filterKey: TracksFilter.Artist,
          filterValue: castedItem.pseudonym,
          value: castedItem.pseudonym
        });
      case TracksFilter.Genre:
        castedItem = (item as Genre);

        return Object.assign(new FilterSelectItem(), {
          displayName: castedItem.displayName,
          filterKey: TracksFilter.Genre,
          filterValue: castedItem.displayName,
          value: castedItem.normalizedName,
          subValues: this.IsNullOrUndefinedOrEmpty(castedItem.subGenres) ? [] :
            castedItem.subGenres.map(q => Object.assign(new FilterSelectItem(), {
              displayName: q.displayName,
              filterKey: 'subGenreNormalizedName',
              filterValue: q.displayName,
              value: q.normalizedName
            }))
        });
      case TracksFilter.Instrument:
        castedItem = (item as Instrument);

        return Object.assign(new FilterSelectItem(), {
          displayName: castedItem.displayName,
          filterKey: TracksFilter.Instrument,
          filterValue: castedItem.displayName,
          value: castedItem.normalizedName
        });
      case TracksFilter.Mood:
        castedItem = (item as Mood);

        return Object.assign(new FilterSelectItem(), {
          displayName: castedItem.displayName,
          filterKey: TracksFilter.Mood,
          filterValue: castedItem.displayName,
          value: castedItem.normalizedName
        });
      case TracksFilter.Theme:
        castedItem = (item as Theme);

        return Object.assign(new FilterSelectItem(), {
          displayName: castedItem.displayName,
          filterKey: TracksFilter.Theme,
          filterValue: castedItem.displayName,
          value: castedItem.normalizedName
        });
      case TracksFilter.Status:
        castedItem = (item as any);

        return Object.assign(new FilterSelectItem(), {
          displayName: castedItem.displayName,
          filterKey: TracksFilter.Status,
          filterValue: castedItem.displayName,
          value: castedItem.normalizedName
        });
      default:
        return { displayName: '', filterValue: '', filterKey: '', value: '' } as FilterSelectItem;
    }
  }

  /**
   * Pushes the current filter inclusion depending on whether it was included priorly or not.
   *
   * {FilterSelectItem} item
   */
  pushIncludeFilter(item: FilterSelectItem): void {
    const filterIndex: number = this.filterService.filters.findIndex(q => q.value === item.filterValue);

    // For item
    if (filterIndex === -1) {
      const newActiveFilter = Object.assign(new MusicFilter(), {
        key: item.filterKey,
        value: item.filterValue,
        title: item.displayName,
        type: 'Include'
      });

      // Add item to filter list
      this.musicFilterService.pushOrUpdateFilterOrSort(newActiveFilter);
    }
  }

  /**
   * Removes the current filter inclusion depending on whether it was included priorly or not.
   *
   * {string} filterName
   * {string[]} values
   */
  removeIncludeFilters(filterName: string, values: string[]): void {
    const currentFilters: MusicFilter[] = this.filterService.filters.filter(q => q.key === filterName);
    currentFilters.map((currentFilter: MusicFilter) => {
      if (values.indexOf(currentFilter.value) === -1) {
        this.filterService.removeFilterByDisplayName(currentFilter.title);
      }
    });
  }

  addAllTracksToPlaylist(currentTrack: { id: string, index: number }): void {
    if (
      !this.IsNullOrUndefinedOrEmpty(this.playlistId) &&
      this.musicPlaylistService.allPlaylistTracksInPlaylist(this.playlistId)
    ) {
      this.musicPlaylistService.setPlaylist(this.dataSource.database.currentSet, currentTrack);
    } else {
      this.musicPlaylistService.addTracksToPlaylist(this.dataSource.database.currentSet, true, currentTrack);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private updateMusicFilter(filterName: string, values: string[] | string, items?: FilterSelectItem[]): void {
    if (filterName === TracksFilter.Search) {
      this.musicFilterService.pushOrUpdateFilterOrSort(
        Object.assign(new MusicFilter(), {
          key: TracksFilter.Search,
          title: values,
          value: values,
          type: 'Include'
        })
      );
    } else {
      if (values instanceof Array) {
        if (values.length > 0) {
          // Add filters
          values.map((filterValue: string) => {
            const item = items.find((filterItem: FilterSelectItem) => filterItem.value === filterValue);
            this.pushIncludeFilter(item);
          });
        }
      }
    }
  }

  private updateFiltersForm(filters: BaseMusicFilterSort[]): void {
    Object.keys(this.filtersForm.controls).map((controlName: string) => {

      const values = this.filtersForm.get(controlName).value;

      if (values instanceof Array === true) {

        const newValues: any[] = [];

        for (const value of values) {
          const filterIndex = filters.findIndex((filter: BaseMusicFilterSort) => filter.value === value && filter.key !== TracksFilter.Search);
          if (filterIndex !== -1) {
            // Push value to new values
            newValues.push(value);
          }
        }

        this.filtersForm.get(controlName).setValue(newValues, { emitEvent: false });
        this.filtersForm.updateValueAndValidity();
        this.cdr.detectChanges();
      } else {
        const searchIndex = filters.findIndex((filter: BaseMusicFilterSort) => filter.key === TracksFilter.Search);

        if (searchIndex === -1) {
          this.filtersForm.get('search').setValue('', { emitEvent: false });
          this.filtersForm.updateValueAndValidity();
          this.cdr.detectChanges();
        }
      }
    });
  }

  private setupPaginator(): void {
    this.paginatorForm = this.fb.group({
      pageSizes: [],
      currentPageSize: [10],
      pageNumber: [0],
      total: [0],
      numberOfPages: [1]
    });
  }

  private getDefaultConfiguration(): DatabaseConfiguration {
    let fullLanguageSupport = false;

    if (this.endpoint.includes('isMySongs') || this.endpoint.includes('isUploads')) {
      fullLanguageSupport = true;
    }

    let resource = this.endpoint;

    if (this.router.url.includes(AppRoute.Favourites)) {
      resource = `users/${this.authenticationService?.user?.id}/tracks?isFavourite=true`
    }

    return Object.assign(new DatabaseConfiguration(), {
      resource,
      endpoint: resource,
      fullLanguageSupport,
      ignoreFilters: this.ignoreFilters
    });
  }

  private setDataSource(): void {
    this.dataSource = new BaseDataSource(
      new BaseDatabase<Track>(
        this.getDefaultConfiguration(),
        this.injector,
        this.paginator,
        this.sort,
        this.filterValuesChanged$,
        this.reflectFiltersInUrl
      )
    );
  }

  private setLocalStorageDataSource(items: Track[], items$: Observable<Track[]>): void {
    this.dataSource = new LocalStorageDataSource(
      new BaseDatabase<Track>(
        this.getDefaultConfiguration(),
        this.injector,
        this.paginator,
        this.sort,
        this.filterValuesChanged$,
        false
      ),
      items,
      items$,
      true
    );
  }

  private getTrackSortFilter(trackSort: any): MusicFilter {
    return Object.assign(new MusicFilter(), {
      key: trackSort.name,
      value: true,
      type: 'Include',
      title: trackSort.displayName
    });
  }

  private updateWidthBySidebarState(): void {
    let baseReduction = 224;

    if (this.isExpanded === true) {
      baseReduction = baseReduction + 200;
    }

    if (this.isBrowseSidebar) {
      baseReduction = baseReduction + 200;
    }

    if (this.isSubSubSidebarExpanded) {
      if (this.isExpanded) {
        baseReduction = baseReduction - 200;
      }
      baseReduction = baseReduction + 280;
    }

    this.viewWidth = window.innerWidth - baseReduction;
  }

  private resize = (event: any): void => {
    this.updateWidthBySidebarState();

    this.setTrackHeaderByViewWidth();
  }

  private setTrackHeaderByViewWidth(): void {
    const trackHeaderToRemove: string[] = [];

    if (this.viewWidth < 1211) {
      trackHeaderToRemove.push(TrackHeader.EnergyLevel);
      this.descriptionColSpan = 7;
      if (this.viewWidth < 1115) {
        trackHeaderToRemove.push(TrackHeader.BPM);
        this.descriptionColSpan = 6;
        if (this.viewWidth < 1040) {
          trackHeaderToRemove.push(TrackHeader.Length);
          this.descriptionColSpan = 5;
          if (this.viewWidth < 943) {
            trackHeaderToRemove.push(TrackHeader.Waveform);
            this.descriptionColSpan = 5;
            if (this.viewWidth < 700) {
              trackHeaderToRemove.push(TrackHeader.Genre);
              trackHeaderToRemove.push(TrackHeader.Actions);
              this.descriptionColSpan = 4;
              if (this.viewWidth < 619) {
                this.descriptionColSpan = 4;
                if (this.viewWidth < 544) {
                  this.descriptionColSpan = 3;
                  if (this.viewWidth < 416) {
                    this.descriptionColSpan = 4;
                  }
                }
              }
            }
          }
        }
      }
    } else {
      this.descriptionColSpan = 8;
    }
    this.trackHeaders = this.TrackHeaders.filter(q => !trackHeaderToRemove.includes(q.value));
  }
}

export class FilterSelectItem {
  displayName: string;
  filterKey: string;
  filterValue: string;
  value: string;
}

export enum TracksFilter {
  Search = 'search',
  Artist = 'artistPseudonym',
  Genre = 'genreNormalizedName',
  Mood = 'moodNormalizedName',
  Instrument = 'instrumentNormalizedName',
  Status = 'variationsStatus',
  Theme = 'themeNormalizedName'
}